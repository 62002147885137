<template>
    <section id="ProjSnapchat">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Snapchat Geofilter" 
                subtitle="Snapchat Geofilter è un sistema progettato per consentire (sull'app Snapchat) di utilizzare la geolocalizzazione per aggiungere filtri grafici alla foto scattata, diversi a seconda della località in cui ci si trova. Questo in particolare è quello che potete trovare a Verbania. Realizzato utilizzando gli elementi caratteristici del luogo: la zona montana e boschiva accompagnata dal Lago Maggiore, che fanno della città una meta turistica di successo." 
            />
        <!-- --------------------- -->

        <div class="alone">
            <img src="@/assets/imgs/proj-snapchat/app-show.gif" alt="">
        </div>

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'

export default {
    name: 'ProjSnapchat',
    components: {
        PageTitle
    },
    metaInfo: {
        title: 'Snapchat Geofilter',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Snapchat Geofilter è un sistema progettato per consentire (sull\'app Snapchat) di utilizzare la geolocalizzazione per aggiungere filtri grafici alla foto scattata, diversi a seconda di dove ti trovi. Questo in particolare è quello che potete trovare a Verbania. Realizzato utilizzando gli elementi caratteristici del luogo: la zona montana e boschiva accompagnata dal Lago Maggiore, che fanno della città una meta turistica di successo.',
        }],
    },
}
</script>


<style scoped lang="scss">
@import '@/scss/_variables.scss';

.alone{
    padding: 25px;
}

</style>